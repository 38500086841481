import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import DrivosityHTH from "../components/OurCompany/DrivosityHTH/DrivosityHTH";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/drivosity-and-hth.jpg"

const DrivosityAndHthPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Drivosity and HTH - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Drivosity and HTH revolutionize the first-party delivery industry with GPS-enabled car toppers. Through real-time GPS tracking, we provide analytics that drives sales and productivity. Explore the benefits and request a demo today."/>

                <meta property="og:title" content="Drivosity and HTH - Drivosity"/>
                <meta property="og:description" content="Drivosity and HTH revolutionize the first-party delivery industry with GPS-enabled car toppers. Through real-time GPS tracking, we provide analytics that drives sales and productivity. Explore the benefits and request a demo today."/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Drivosity signage on a moving vehicle in a busy urban setting, symbolizing smart driver solutions and fleet management technology."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/drivosity-and-hth`}/>
                
            </Helmet>
            <Layout component={<DrivosityHTH/>}/>
        </>
    )
}

export default DrivosityAndHthPage;
